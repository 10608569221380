import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useRoleList() {
  const roleList = ref([])
  const roleData = ref({})

  const tableColumns = [
    { text: 'CODE', value: 'code' },
    { text: 'ROLE', value: 'display' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const statusFilter = ref(null)
  const totalRoleListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['display'],
    sortDesc: [false],
  })
  const roleTotalLocal = ref([])
  const selectedRows = ref([])
  const selectedRole = ref([])

  // fetch data
  const fetchRoles = () => {
    store
      .dispatch('app-role/fetchRoles')
      .then(response => {
        roleList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchRoles due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedRole.value = []
    fetchRoles()
  })

  // post data
  const postRole = roleDTO => {
    store
      .dispatch('app-role/postRole', roleDTO)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        alert(`Error for postRole due to ${error}`)
      })
  }

  watch([], () => {
    postRole(roleData)
  })

  // delete data
  const deleteRole = roleDTO => {
    store
      .dispatch('app-role/deleteRole', roleDTO)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        alert(`Error for deleteRole due to ${error}`)
      })
  }

  watch([], () => {
    deleteRole(roleData)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRoleStatusVariant = status => {
    if (status === 'Pending') return 'warning'
    if (status === 'Active') return 'success'
    if (status === 'Inactive') return 'secondary'

    return 'primary'
  }

  const resolveRoleTotalIcon = total => {
    if (total === 'Total roles') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Active roles') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending roles') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const readRole = roles => {
    const userRoles = new Array('None')

    if (roles !== undefined) {
      roles.forEach((role, index) => {
        if (role !== undefined && role.id != null) {
          userRoles[index] = role.role
        }
      })
    }

    return userRoles
  }

  return {
    tableColumns,
    searchQuery,
    statusFilter,
    totalRoleListTable,
    loading,
    options,
    roleTotalLocal,
    selectedRows,
    roleList,
    selectedRole,

    roleData,
    readRole,
    deleteRole,
    fetchRoles,
    postRole,
    resolveRoleStatusVariant,
    resolveRoleTotalIcon,
  }
}
