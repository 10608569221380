import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.isRoleFormActive),callback:function ($$v) {_vm.isRoleFormActive=$$v},expression:"isRoleFormActive"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Add Role")])]),_c(VCardText,[_c(VForm,{ref:"roleForm",on:{"submit":_vm.validate}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VTextField,{attrs:{"label":"Role ID","hint":"GUID","readonly":""},model:{value:(_vm.roleDTO.id),callback:function ($$v) {_vm.$set(_vm.roleDTO, "id", $$v)},expression:"roleDTO.id"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VSwitch,{attrs:{"color":"primary","model-value":true,"label":"Active Status","readonly":!_vm.isEdit},model:{value:(_vm.roleDTO.isActive),callback:function ($$v) {_vm.$set(_vm.roleDTO, "isActive", $$v)},expression:"roleDTO.isActive"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VTextField,{attrs:{"label":"Role Code","hint":"SA","rules":[_vm.required],"readonly":!_vm.isEdit},model:{value:(_vm.roleDTO.code),callback:function ($$v) {_vm.$set(_vm.roleDTO, "code", $$v)},expression:"roleDTO.code"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VTextField,{attrs:{"label":"Role Display Name","hint":"Site Admin","rules":[_vm.required],"readonly":!_vm.isEdit},model:{value:(_vm.roleDTO.display),callback:function ($$v) {_vm.$set(_vm.roleDTO, "display", $$v)},expression:"roleDTO.display"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error","type":"reset","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.submitForm(_vm.roleDTO)}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }