import ApiConfig from './ApiConfig'
//import { RoleClient_V1 } from './generated/api.generated.v1'
import variables from '@/variables'


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // ROLES

    /*
    fetchRoles() {
      const api = new RoleClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getRoles()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postRole(context, roleDTO) {
      //console.log(roleDTO)

      const userId = '6808891f-4518-4a65-bb63-10d7989cb125' // Change to userData.id
      const api = new RoleClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postRole(userId, roleDTO)
          .then(response => resolve(response) && console.log(response))
          .catch(error => reject(error))
      })
    },

    deleteRole(context, roleDTO) {
      // const userData = JSON.parse(localStorage.getItem('userData')) // Uncomment

      // const userId = '6808891f-4518-4a65-bb63-10d7989cb125' // Change to userData.id
      const api = new RoleClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deleteRole(roleDTO.id)
          .then(response => resolve(response) && console.log(response))
          .catch(error => reject(error))
      })
    },
    */
  },
}


