<template>
  <div id="role-list">
    <!-- dialog -->
    <role-add-edit
      v-model="isRoleFormActive"
      :is-edit="isEdit"
      :role-d-t-o="selectedItem"
      @add-role="isRoleFormActive"
      @refetch-data="fetchRoles"
    ></role-add-edit>

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchRoles"
    ></delete-confirmation>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="role-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isRoleFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="isRoleFormActive = !isRoleFormActive, isEdit = true, selectedItem = {}"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Role</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRoles"
        :headers="tableColumns"
        :items="roleList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isRoleFormActive = !isRoleFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isRoleFormActive = !isRoleFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="selectItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>Disable</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import roleService from '@/services/RoleService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useRoleList from '@/store/useRoleService'
import RoleAddEdit from './RoleAddEdit.vue'

export default {
  components: { RoleAddEdit, DeleteConfirmation },
  setup() {
    const ROLE_APP = 'app-role'

    // Register module
    if (!store.hasModule(ROLE_APP)) store.registerModule(ROLE_APP, roleService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP)) store.unregisterModule(ROLE_APP)
    })

    const {
      roleList,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRoles,

      fetchRoles,
      deleteRole,
    } = useRoleList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isRoleFormActive = ref(false)

    return {
      roleList,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRoles,
      isRoleFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,

      deleteRole,
      fetchRoles,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchRoles()
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      //console.log(this.selectedItem)
    },
    submitDelete(roleDTO) {
      this.deleteRole(roleDTO)
    },
  },
}
</script>
