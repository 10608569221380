<template>
  <div>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="isRoleFormActive"
          persistent
          max-width="50%"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Add Role</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="roleForm"
                @submit="validate"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="roleDTO.id"
                      label="Role ID"
                      hint="GUID"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-switch
                      v-model="roleDTO.isActive"
                      color="primary"
                      :model-value="true"
                      label="Active Status"
                      :readonly="!isEdit"
                    >
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="roleDTO.code"
                      label="Role Code"
                      hint="SA"
                      :rules="[required]"
                      :readonly="!isEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="roleDTO.display"
                      label="Role Display Name"
                      hint="Site Admin"
                      :rules="[required]"
                      :readonly="!isEdit"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      color="error"
                      type="reset"
                      text
                      @click="closeForm()"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="submitForm(roleDTO)"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import roleService from '@/services/RoleService'
import useRoleList from '@/store/useRoleService'

export default {
  model: {
    prop: 'isRoleFormActive',
    event: 'update:is-role-form-active',
  },
  props: {
    isRoleFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    roleDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const roleForm = ref(null)
    const { postRole } = useRoleList()

    const validate = roleDTO => {
      if (roleForm.value.validate(roleDTO)) {
        return true
      }

      return false
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update:is-role-form-active', false)
    }

    const ROLE_APP = 'app-role'

    // Register module
    if (!store.hasModule(ROLE_APP)) store.registerModule(ROLE_APP, roleService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP)) store.unregisterModule(ROLE_APP)
    })

    return {
      roleForm,
      validate,
      closeForm,
      refreshParent,

      postRole,

      required,

      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    submitForm(roleDTO) {
      if (this.validate(roleDTO)) {
        this.postRole(roleDTO)
        this.closeForm()
        this.refreshParent()
      } else {
        //console.log('Not Valid')
      }
    },
  },
}
</script>
